(function() {
    'use strict';
    angular.module('eventvods')
		.factory('EventsService', ['$http', 'API_BASE_URL', function ($http, API_BASE_URL) {
			return {
				get: function () {
					return $http.get(API_BASE_URL + '/events');
				}
			};
		}])
        .controller("EventsListController", ['EventsService', 'GamesList', '$location', '$rootScope', '$window','$timeout', 'ElementBGCount',
			function (EventsService, GamesList, $location, $rootScope, $window, $timeout, ElementBGCount) {
				$window.prerenderReady = false;
				var vm = this;
				// Data
				vm.events = [];
				vm.show = false;
				vm.limits = 12;
				var randomOrder = Array.from(new Array(ElementBGCount).join(".").split(".").keys()).sort(function(a, b){return 0.5 - Math.random()});
				vm.page = 1;

				// Cards obj
				vm.games = GamesList;

				// Selected game
				vm.game = $rootScope.oldGame || null;

				var path_game = $location.path().replace('/events/','');
				if(path_game in GamesList) vm.game = path_game;
				$rootScope.game = vm.game;

				function setTitle(){
					$timeout(function(){
						if(vm.game === null){
							document.title = $rootScope.meta.title = "Browse VODs - Eventvods";

						}
						else {
							document.title = $rootScope.meta.title = GamesList[vm.game] + " VODs - Eventvods";
							$rootScope.meta.description = "Browse "  + GamesList[vm.game] + " esports vods and highlights for all the latest events, easily and spoiler-free. Rate matches of your favorite teams and share them with friends."
						}
					}, 0);
				}
				setTitle();

				//Sorting
				vm.sorts = [
					{"text": "Latest Events", "field": "sortDate", reverse: true},
					{"text": "Last Updated", "field": "updatedAt", reverse: true},
					{"text": "Most Popular", "field": "followers", reverse: true}
				];
				vm.sort = vm.sorts[0];

				vm.elementClass = function($index) {
					var img = randomOrder[($index % ElementBGCount)] + 1;
                    return "element-" + img;
                }

				vm.toggleGame = function(game){
					vm.show = false;
					if(game == vm.game || game == null){
						vm.game = null;
						$location.path('/events/', false);
					}
					else if(game in GamesList) {
						vm.game = game;
						$location.path('/events/'+game, false);
					}
					$rootScope.game = vm.game;
					setTitle();
					vm.show = true;
					$timeout(function(){
						$('[data-toggle="tooltip"]').tooltip('dispose');
						$('[data-toggle="tooltip"]').tooltip();
					}, 0);
				}
				$rootScope.$on('eventsReset', function(){
					vm.toggleGame(null);
					$timeout(function(){
						$('[data-toggle="tooltip"]').tooltip('dispose');
						$('[data-toggle="tooltip"]').tooltip();
					}, 0);
				});
				EventsService.get()
					.then(function (res) {
						vm.events = res.data.map(function(event){
							event.diff = 0;
							event.sortDate = event.endDate;
							event.updateString = "Last updated at " + $window.moment(event.updatedAt).format("MMMM Do YYYY, h:mm A");
							return event;
						});
						vm.show = true;
  						$timeout(function(){
							  $('[data-toggle="tooltip"]').tooltip();
							  $('.element-container a').click(function(evt){
									var el = $(this).closest('.element-border').attr('class').split(" ")[1];
									$rootScope.$apply(function(){
										$rootScope.oldGame = $rootScope.game;
										$rootScope.game = el;
									});
								});
								$window.prerenderReady = true;
						  }, 1);
					});
		}]);
}());
